import React, {Fragment, cloneElement, Children} from 'react'
import classnames from 'classnames'
import Spinner from 'rambler-ui/Spinner'
import PageHeading from 'components/PageHeading'
import SectionHeading from 'components/SectionHeading'
import styles from './styles.css'

type PageContentProps = React.HTMLProps<HTMLDivElement> & {
  fullHeight?: boolean
  heading?:
    | React.ReactElement<typeof PageHeading>
    | React.ReactElement<typeof SectionHeading>
  control?: React.ReactElement
  showSpinner?: boolean
  forwardRef?: React.RefObject<HTMLDivElement>
}

const PageContent: React.FC<PageContentProps> = ({
  className,
  fullHeight,
  children,
  heading,
  control,
  showSpinner,
  forwardRef,
  ...props
}) => (
  <div
    className={classnames(
      className,
      styles.root,
      fullHeight && styles.fullHeight
    )}
    ref={forwardRef}
    {...props}>
    {control &&
      cloneElement(control, {
        className: classnames(styles.control, control.props.className)
      })}
    {heading &&
      cloneElement(heading, {
        className: classnames(styles.heading, heading.props.className)
      })}
    {children}

    {showSpinner && (
      <Fragment>
        <div className={styles.overlay} />
        <Spinner className={styles.spinner} inline />
      </Fragment>
    )}
  </div>
)

export default PageContent

type PageContentGridProps = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactElement[]
}

export const PageContentGrid: React.FC<PageContentGridProps> = ({
  className,
  children,
  ...props
}) => (
  <div {...props} className={classnames(className, styles.gridWrapper)}>
    {Children.map(children, (item) =>
      cloneElement(item, {
        className: classnames(item.props.className, styles.gridItem)
      })
    )}
  </div>
)
