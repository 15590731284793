import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type Props = {
  aside?: React.ReactNode
  asideStyle?: React.CSSProperties
  contentClassName?: string
  children: React.ReactNode
  className?: string
  forwardRef?: React.RefObject<HTMLElement>
  forwardAsideRef?: React.RefObject<HTMLElement>
}

const Page: React.FC<Props> = ({
  aside,
  asideStyle,
  contentClassName,
  children,
  className,
  forwardRef,
  forwardAsideRef
}) => (
  <main
    className={classnames(className, styles.root, 'redesigned')}
    ref={forwardRef}>
    {aside && (
      <aside className={styles.aside} style={asideStyle} ref={forwardAsideRef}>
        {aside}
      </aside>
    )}
    <div className={classnames(contentClassName, styles.content)}>
      {children}
    </div>
  </main>
)

export default Page
