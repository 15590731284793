import React from 'react'

export const stopPropagation = (ev: React.UIEvent<HTMLElement>): void =>
  ev.stopPropagation()

export const preventDefault = (ev: React.UIEvent<HTMLElement>): void =>
  ev.preventDefault()

const INPUT_VALUE_TYPES: string[] = ['text', 'number', 'time', 'date']

export const getValueFromEvent = (value: any): any => {
  if (value && typeof value === 'object') {
    const {target} = value

    if (target instanceof HTMLTextAreaElement) {
      return target.value
    }

    if (target instanceof HTMLInputElement) {
      if (target.type === 'checkbox') return target.checked
      if (INPUT_VALUE_TYPES.includes(target.type)) return target.value
    }
  }

  return value
}
