import {getItem, setItem} from '@rambler-tech/local-storage'
import {isObject} from 'utils/checkType'

const LOCAL_STORAGE_KEY = 'vpadmin:userVisits'

const getUserVisits = (): Record<number, true> => {
  const data = getItem<Record<number, true>>(LOCAL_STORAGE_KEY)

  return isObject(data) ? data : {}
}

export const hasUserVisited = (userId: number): boolean =>
  getUserVisits().hasOwnProperty(userId)

export const saveUserVisit = (userId: number): void => {
  setItem(LOCAL_STORAGE_KEY, {
    ...getUserVisits(),
    [userId]: true
  })
}
