import {isString} from 'utils/checkType'

export const trimStringParams = <O extends Record<string, any>>(
  input: O
): O => {
  const output = {...input}

  Object.keys(output).forEach((key: keyof O) => {
    const value = output[key]
    if (isString(value)) output[key] = value.trim()
  })

  return output
}

export const randomString = (): string => {
  const tid = Date.now().toString(36)
  const uid = Math.random().toString(36).slice(2)

  return (tid + uid).slice(0, 17)
}

export const pluralize = (
  value: number,
  [
    a, // singular used for numbers ending in 1, except 11 (1, 21, 31...)
    b, // special case for numbers ending in 2-4, except 12-14 (2-4, 22-24, 32-34...)
    c = b // numbers ending in 11-14 use plural (11-14, 111-114, 211-214...)
  ]: [string, string, string?]
): string => {
  const choices = [a, b, c] as const
  const index =
    value % 10 === 1 && value % 100 !== 11
      ? 0
      : value % 10 >= 2 &&
          value % 10 <= 4 &&
          (value % 100 < 10 || value % 100 >= 20)
        ? 1
        : 2

  return choices[index].replace('***', String(value))
}
