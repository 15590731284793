import {createContext} from 'react'
import {type PartNumbers} from 'transport/v3/uploader'
import {type RecordUploadingState} from 'transport/v3/records'

export type Item = {
  id: string
  progress: number
  partNumbers: PartNumbers
  status: null | 'IN_PROGRESS' | 'FAILED' | 'ABORTED' | 'COMPLETED'
  uploaderStatus: null | RecordUploadingState
  completedAt: null | Date
  uploadId: string | null
  file: File
  recordId: number | null
  replacedRecordId: number | null
  accountName: string
  accountCode: string
  apiOrigin: string
  soundDisabled: boolean
}

export type State = {
  data: Record<string, Item>
  list: string[]
  queue: string[]
  itemIds: Record<number, string>
}

export type ContextType = State & {
  getItems: () => Record<number, undefined | Item>
  uploadFiles: (arg0: {
    files: FileList
    accountName: string
    accountCode: string
    apiOrigin: string
    replacedRecordId?: number
    soundDisabled?: boolean
  }) => Promise<void>
  abortUploading: (itemId: string) => void
  retryUploading: (itemId: string) => Promise<void>
  deleteUploading: (itemId: string, deleteRecord?: boolean) => Promise<void>
  getItemByRecordId: (recordId: number) => void | Item
}

export const DEFAULT_STATE: State = {
  data: {},
  list: [],
  queue: [],
  itemIds: {}
}

export default createContext<ContextType>({
  ...DEFAULT_STATE,
  getItems: () => ({}),
  uploadFiles: () => Promise.reject(),
  abortUploading: () => {}, // eslint-disable-line
  retryUploading: () => Promise.reject(),
  deleteUploading: () => Promise.reject(),
  getItemByRecordId: () => {}
})
