import React, {useContext, useMemo} from 'react'
import {Link, NavLink, useLocation} from 'react-router-dom'
import classnames from 'classnames'
import NavDropdown from './NavDropdown'
import AppContext from 'containers/App/context'
import LayoutProviderContext from 'containers/LayoutProvider/context'
import HelpDropdown from './HelpDropdown'
import AccountsDropdown from './AccountsDropdown'
import UserDropdown from './UserDropdown'
import type {AccountAvailableSection} from 'types/Account'
import Logo from './logo.svg'
import styles from './styles.css'

const STAT_ROUTE = 'stat/vod/all'
const SETTINGS_ROUTE = 'setting/account'
const COMMAND_CENTER_ROUTE = 'cc/accounts'
const COPYRIGHT_HOLDERS_ROUTE = 'kpo/holders'
const ANALYTICS_ROUTE = 'analytics/stat'

export type Route = {
  label: string
  route?: string
  matchSections?: string[]
  account?: boolean
  options?: Route[]
  id?: string
  requiredFeature?: AccountAvailableSection
  beta?: string[]
}

const ACCOUNT_ROUTES: Route[] = [
  {
    label: 'Видео',
    matchSections: ['filter', 'filters', 'albums', 'editor'],
    route: 'records',
    account: true,
    requiredFeature: 'VIDEO'
  },
  {
    label: 'Трансляции',
    route: 'livestreams',
    account: true,
    requiredFeature: 'LIVESTREAM'
  },
  {
    label: 'Статистика',
    matchSections: ['stat'],
    route: STAT_ROUTE,
    account: true
  },
  {
    label: 'Настройки',
    matchSections: ['setting', 'users'],
    route: SETTINGS_ROUTE,
    account: true
  }
]

const COMMON_ROUTES: Route[] = [
  {
    label: 'Командный центр',
    matchSections: ['cc'],
    route: COMMAND_CENTER_ROUTE
  },
  {
    label: 'КПО',
    matchSections: ['kpo'],
    route: COPYRIGHT_HOLDERS_ROUTE
  },
  {
    label: 'Аналитика',
    matchSections: ['analytics'],
    route: ANALYTICS_ROUTE
  }
]

const MORE_OPTION: Route = {
  label: 'Ещё',
  matchSections: ['cc', 'kpo', 'analytics'],
  options: COMMON_ROUTES,
  id: 'more'
}

type Props = {
  hideUser?: boolean
  hideContacts?: boolean
}

const Topline: React.FC<Props> = (props) => {
  const {toplineTopPosition, toplineRef} = useContext(LayoutProviderContext)

  const {user, getAccountPermissions, getPath, permissions} =
    useContext(AppContext)

  const {pathname} = useLocation()
  const {
    availableSections,
    accountCode,
    permissions: {yauth, media}
  } = getAccountPermissions()
  const isAccountValid = !!accountCode

  const routes = useMemo(() => {
    const resultRoutes = ACCOUNT_ROUTES.filter((item) => {
      const {route, account, requiredFeature} = item

      if (isAccountValid && account) {
        if (requiredFeature) return availableSections.includes(requiredFeature)
        if (route === STAT_ROUTE) return yauth.can_view_account_stat
        if (route === SETTINGS_ROUTE)
          return [
            yauth.can_manage_account,
            media.can_create_metadata,
            media.can_manage_player_templates,
            media.can_create_site_access_templates,
            media.can_create_country_access_templates,
            media.can_manage_ad_templates
          ].some(Boolean)

        return true
      }
    })

    const commonRoutes = (MORE_OPTION.options || []).filter(({route}) => {
      if (route === COMMAND_CENTER_ROUTE || route === COPYRIGHT_HOLDERS_ROUTE) {
        return user.admin
      }

      if (route === ANALYTICS_ROUTE) {
        return (
          Object.values(permissions || {}).filter(
            (item) => item.permissions.media.can_view_accounts_statistics
          ).length > 1
        )
      }
    })

    if (isAccountValid) {
      if (commonRoutes.length) {
        resultRoutes.push({
          ...MORE_OPTION,
          options: commonRoutes
        })
      }
    } else {
      resultRoutes.push(...commonRoutes)
    }

    return resultRoutes
  }, [isAccountValid, media, permissions, user, yauth])

  const style =
    toplineTopPosition != null
      ? ({
          position: toplineTopPosition > 0 ? 'absolute' : 'sticky',
          top: toplineTopPosition,
          zIndex: 102
        } as const)
      : undefined

  return (
    <header
      className={classnames(styles.root, 'redesigned')}
      style={style?.position === 'sticky' ? style : undefined}
      ref={toplineRef}>
      <div
        className={styles.inner}
        style={style?.position === 'absolute' ? style : undefined}>
        <div className={styles.content}>
          {isAccountValid ? (
            <Link className={styles.logo} to={getPath('records')}>
              <Logo />
            </Link>
          ) : (
            <span className={styles.logo}>
              <Logo />
            </span>
          )}
          <nav className={styles.nav}>
            {routes.map((item) => {
              const {label, route, account, matchSections, options, id} = item

              if (options) {
                return <NavDropdown key={route || id} {...item} />
              }

              return (
                <NavLink
                  caseSensitive
                  className={({isActive}) =>
                    classnames(
                      styles.navItem,
                      (isActive ||
                        matchSections?.some((item) =>
                          pathname.startsWith(
                            account ? getPath(item) : `/${item}`
                          )
                        )) &&
                        styles.activeItem
                    )
                  }
                  key={route}
                  to={account ? getPath(route as string) : `/${route}`}>
                  {label}
                </NavLink>
              )
            })}
          </nav>

          {!props.hideContacts && <HelpDropdown />}

          <AccountsDropdown />

          {!props.hideUser && <UserDropdown />}
        </div>
      </div>
    </header>
  )
}

export default Topline
