import React, {createRef, createContext} from 'react'

type UnsubscribeElement = () => void

export type StickyElementStyle = Pick<React.CSSProperties, 'position' | 'top'>

export type StickyElementCallback = (style: StickyElementStyle) => any

export type SubscribeStickyElement = (
  callback: StickyElementCallback,
  topOffset: number,
  containerRef: React.RefObject<HTMLElement>,
  elementRef: React.RefObject<HTMLElement>
) => UnsubscribeElement

export type LeavingElementCallback = (isVisible: boolean) => any

export type SubscribeLeavingElement = (
  callback: LeavingElementCallback,
  elementRef: React.RefObject<HTMLElement>
) => UnsubscribeElement

export type ContextType = {
  toplineTopPosition: null | number
  toplineRef: React.RefObject<HTMLElement>
  setIsFooterSticky: (isSticky: boolean) => void
  setIsFooterVisible: (isVisible: boolean) => void
  footerPortalRef: React.RefObject<HTMLElement>
  subscribeStickyElement: SubscribeStickyElement
  subscribeLeavingElement: SubscribeLeavingElement
  isScrollButtonActive: boolean
}

export default createContext<ContextType>({
  toplineTopPosition: null,
  toplineRef: createRef(),
  setIsFooterSticky: () => {},
  setIsFooterVisible: () => {},
  footerPortalRef: createRef(),
  subscribeStickyElement: () => () => {},
  subscribeLeavingElement: () => () => {},
  isScrollButtonActive: false
})
