import React, {cloneElement} from 'react'
import classnames from 'classnames'
import {preventDefault} from 'utils/events'
import styles from './styles.css'

type Props = {
  className?: string
  container?: React.ReactElement
  iconComponent: React.ComponentType
  rightIcon?: boolean
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>
  highlightOnHover?: boolean
  disabled?: boolean
  type?: 'danger'
  forwardRef?: React.Ref<HTMLSpanElement>
}

const IconText: React.FC<Props> = ({
  className,
  container = <span />,
  iconComponent: Icon,
  rightIcon,
  children,
  onClick,
  highlightOnHover,
  disabled,
  type,
  forwardRef,
  ...otherProps
}) => {
  const resultChildren = [
    <Icon key="icon" className={styles.icon} size={null} color={null} />,
    <span key="text" className={styles.text}>
      {children}
    </span>
  ]

  if (rightIcon) resultChildren.reverse()

  return cloneElement(
    container,
    {
      onClick: disabled ? preventDefault : onClick,
      className: classnames(
        className,
        styles.root,
        type && styles[type],
        highlightOnHover && styles.hoverable,
        onClick && styles.clickable,
        disabled && styles.disabled
      ),
      disabled,
      ...(typeof container.type === 'string' && {
        ref: forwardRef
      }),
      ...otherProps
    },
    resultChildren
  )
}

export default IconText
