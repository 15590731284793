import React, {useEffect, useMemo, useState, useRef} from 'react'
import classnames from 'classnames'
import throttle from 'raf-throttle'
import {PageFooter} from 'components/PageFooter/Redesigned'
import LayoutProviderContext, {
  type ContextType,
  type StickyElementStyle,
  type LeavingElementCallback,
  type StickyElementCallback,
  type SubscribeStickyElement,
  type SubscribeLeavingElement
} from './context'
import styles from './styles.css'

enum PositionType {
  STATIC,
  STICKY_TO_TOP,
  STICKY_TO_TOPLINE,
  STICKY_TO_BOTTOM,
  CONTAINER_TOP
}

interface StickyElementCallbackWithCache extends StickyElementCallback {
  position: PositionType
}

type StickyElementSubscriber = Readonly<
  [
    StickyElementCallbackWithCache,
    number,
    React.RefObject<HTMLElement>,
    React.RefObject<HTMLElement>
  ]
>

interface LeavingElementCallbackWithCache extends LeavingElementCallback {
  isVisible: boolean
}

type LeavingElementSubscriber = Readonly<
  [LeavingElementCallbackWithCache, React.RefObject<HTMLElement>]
>

const SCROLL_BUTTON_VISIBILITY_THRESHOLD = 10

const LayoutProvider: React.FC<{children?: React.ReactNode}> = (props) => {
  const [toplineTopPosition, setToplineTopPosition] = useState<number | null>(
    null
  )

  const {documentElement} = document
  const [isScrollButtonActive, setIsScrollButtonActive] = useState(
    documentElement.scrollTop > SCROLL_BUTTON_VISIBILITY_THRESHOLD
  )
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false)
  const [isFooterSticky, setIsFooterSticky] = useState<boolean>(false)

  // Используются в колбеке подписки и обработчике события скрола страницы
  const toplineTopPositionRef = useRef(toplineTopPosition)
  const isFooterStickyRef = useRef(isFooterVisible && isFooterSticky)

  useEffect(() => {
    toplineTopPositionRef.current = toplineTopPosition
    isFooterStickyRef.current = isFooterVisible && isFooterSticky
  })

  const toplineRef = useRef<HTMLElement>(null)
  const footerRef = useRef<HTMLElement>(null)
  const footerPortalRef = useRef<HTMLElement>(null)

  const [subscribeStickyElement, stickyElementsSubscribers] = useMemo(() => {
    const list: StickyElementSubscriber[] = []

    const subscribe: SubscribeStickyElement = (
      calback,
      topOffset,
      containerRef,
      elementRef
    ) => {
      const toplineTopPosition = toplineTopPositionRef.current
      const toplineHeight = toplineRef.current?.offsetHeight
      const containerRect = containerRef.current?.getBoundingClientRect()
      const elementRect = elementRef.current?.getBoundingClientRect()
      let style: StickyElementStyle = {position: 'sticky', top: topOffset}
      let position = PositionType.STICKY_TO_TOP

      if (
        toplineTopPosition != null &&
        toplineHeight != null &&
        elementRect != null &&
        containerRect != null
      ) {
        if (containerRect.height <= elementRect.height) {
          position = PositionType.STATIC
          style = {}
        } else if (toplineTopPosition) {
          const visibleHeight =
            toplineHeight + toplineTopPosition - documentElement.scrollTop

          if (visibleHeight > containerRect.top) {
            position = PositionType.CONTAINER_TOP
            style = {
              position: 'absolute',
              top: Math.abs(containerRect.top) + visibleHeight + topOffset
            }
          }
        } else {
          position = PositionType.STICKY_TO_TOPLINE
          style = {
            position: 'sticky',
            top: toplineHeight + topOffset
          }
        }

        calback(style)
      }

      const item: StickyElementSubscriber = [
        // Колбек с предыдущим значением
        Object.assign(calback, {position}),
        topOffset,
        containerRef,
        elementRef
      ]

      list.push(item)

      // Отписаться
      return () => {
        const index = list.indexOf(item)
        if (index > -1) list.splice(index, 1)
      }
    }

    return [subscribe, list]
  }, [])

  const [subscribeLeavingElement, leavingElementsSubscribers] = useMemo(() => {
    const list: LeavingElementSubscriber[] = []

    const subscribe: SubscribeLeavingElement = (calback, elementRef) => {
      const toplineTopPosition = toplineTopPositionRef.current
      const toplineHeight = toplineRef.current?.offsetHeight
      const footer = footerRef.current
      const elementRect = elementRef.current?.getBoundingClientRect()
      let isVisible = true

      if (toplineHeight != null && elementRect != null) {
        const footerHeight =
          (isFooterStickyRef.current && footer?.offsetHeight) || 0
        const elementTop = elementRect.top
        const elementBottom = elementTop + elementRect.height
        const {offsetHeight, scrollTop} = documentElement

        const documentTop =
          toplineTopPosition == null
            ? 0
            : toplineTopPosition === 0
              ? toplineHeight
              : toplineHeight + toplineTopPosition - scrollTop

        const documentBottom = offsetHeight - footerHeight

        isVisible = documentTop < elementBottom && elementTop < documentBottom
      }

      if (!isVisible) calback(false)

      const item: LeavingElementSubscriber = [
        // Колбек с предыдущим значением
        Object.assign(calback, {isVisible}),
        elementRef
      ]

      list.push(item)

      // Отписаться
      return () => {
        const index = list.indexOf(item)
        if (index > -1) list.splice(index, 1)
      }
    }

    return [subscribe, list]
  }, [])

  useEffect(() => {
    let prevScrollTop = documentElement.scrollTop
    let prevToplineTopPosition = toplineTopPosition
    let prevIsScrollButtonActive = isScrollButtonActive

    const onScroll = throttle(() => {
      const {scrollTop, offsetHeight: documentHeight} = documentElement
      const toplineHeight = toplineRef.current?.offsetHeight
      const footerHeight =
        (isFooterStickyRef.current && footerRef.current?.offsetHeight) || 0

      if (!toplineHeight || scrollTop === prevScrollTop) return

      const scrollUp = scrollTop < prevScrollTop
      const offset = scrollTop - toplineHeight
      let toplineTopPosition = prevToplineTopPosition

      // Логика топлайна
      if (scrollUp) {
        // При скроле вверх страницы
        if (scrollTop === 0) toplineTopPosition = null
        if (toplineTopPosition == null && offset > 0)
          toplineTopPosition = offset
        else if (toplineTopPosition != null && scrollTop < toplineTopPosition)
          toplineTopPosition = 0
      } else {
        // При скроле вниз страницы
        if (toplineTopPosition && toplineTopPosition < offset)
          toplineTopPosition = null
        else if (scrollTop > prevScrollTop && toplineTopPosition === 0)
          toplineTopPosition = scrollTop
      }

      if (toplineTopPosition !== prevToplineTopPosition) {
        prevToplineTopPosition = toplineTopPosition
        setToplineTopPosition(toplineTopPosition)
      }

      const isScrollButtonActive =
        scrollTop > SCROLL_BUTTON_VISIBILITY_THRESHOLD

      if (isScrollButtonActive !== prevIsScrollButtonActive) {
        prevIsScrollButtonActive = isScrollButtonActive
        setIsScrollButtonActive(isScrollButtonActive)
      }

      const documentBottom = documentHeight - footerHeight
      const documentTop =
        toplineTopPosition == null
          ? 0
          : toplineTopPosition === 0
            ? toplineHeight
            : toplineHeight + toplineTopPosition - scrollTop

      // Логика прилипающего элемента
      stickyElementsSubscribers.forEach(
        ([callback, topOffset, containerRef, elementRef]) => {
          const bottomOffset = topOffset
          const containerRect = containerRef.current?.getBoundingClientRect()
          const elementRect = elementRef.current?.getBoundingClientRect()
          if (!containerRect || !elementRect) return

          const {top: containerTop, height: containerHeight} = containerRect
          const {
            top: elementTop,
            height: elementHeight,
            bottom: elementBottom
          } = elementRect

          let position: undefined | PositionType
          let top = 0

          const hasEnoughHeight =
            topOffset + elementHeight + bottomOffset <= documentBottom

          if (containerHeight <= elementHeight) {
            position = PositionType.STATIC
          } else if (hasEnoughHeight) {
            if (toplineTopPosition === null) {
              position = PositionType.STICKY_TO_TOP
            } else if (toplineTopPosition === 0) {
              position = PositionType.STICKY_TO_TOPLINE
            } else {
              const visibleToplineHeight =
                toplineHeight + toplineTopPosition - scrollTop

              if (visibleToplineHeight === toplineHeight) {
                position = PositionType.STICKY_TO_TOPLINE
              } else if (
                visibleToplineHeight === 0 ||
                (containerTop > 0 &&
                  containerTop - visibleToplineHeight >= topOffset)
              ) {
                position = PositionType.STICKY_TO_TOP
              } else {
                position = PositionType.CONTAINER_TOP
                top = Math.min(
                  topOffset + visibleToplineHeight - containerTop,
                  containerHeight - elementHeight
                )
              }
            }
          } else {
            if (containerTop >= documentTop + topOffset) {
              position = PositionType.STICKY_TO_TOP
            } else if (scrollUp) {
              // При скроле вверх страницы
              if (
                toplineTopPosition === 0 &&
                elementTop >= documentTop + topOffset
              ) {
                position = PositionType.STICKY_TO_TOPLINE
              } else {
                position = PositionType.CONTAINER_TOP
                top = Math.max(elementTop - containerTop, 0)
              }
            } else {
              // При скроле вниз страницы
              if (elementBottom + bottomOffset > documentBottom) {
                position = PositionType.CONTAINER_TOP
                top = Math.max(documentTop + topOffset - containerTop, 0)
              } else {
                position = PositionType.STICKY_TO_BOTTOM
              }
            }
          }

          if (position == null || position === callback.position) return

          let style: undefined | StickyElementStyle

          if (position === PositionType.STATIC) style = {}
          else if (position === PositionType.STICKY_TO_BOTTOM)
            style = {
              position: 'sticky',
              top: documentBottom - bottomOffset - elementHeight
            }
          else if (position === PositionType.STICKY_TO_TOP)
            style = {position: 'sticky', top: topOffset}
          else if (position === PositionType.STICKY_TO_TOPLINE)
            style = {position: 'sticky', top: toplineHeight + topOffset}
          else if (position === PositionType.CONTAINER_TOP)
            style = {position: 'absolute', top}

          if (style) {
            callback.position = position
            callback(style)
          }
        }
      )

      // Логика элемента, скрывающегося за пределы вьюпорта
      leavingElementsSubscribers.forEach(([callback, elementRef]) => {
        const elementRect = elementRef.current?.getBoundingClientRect()
        if (!elementRect) return

        const {top: elementTop, bottom: elementBottom} = elementRect
        const isVisible =
          documentTop < elementBottom && elementTop < documentBottom

        if (isVisible !== callback.isVisible) {
          callback.isVisible = isVisible
          callback(isVisible)
        }
      })

      prevScrollTop = scrollTop
    })

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  const context = useMemo(
    () =>
      ({
        toplineTopPosition,
        toplineRef,
        setIsFooterSticky,
        setIsFooterVisible,
        footerPortalRef,
        isScrollButtonActive,
        subscribeStickyElement,
        subscribeLeavingElement
      }) as ContextType,
    [
      toplineTopPosition,
      subscribeStickyElement,
      subscribeLeavingElement,
      isScrollButtonActive
    ]
  )

  return (
    <LayoutProviderContext.Provider value={context}>
      {props.children}

      <PageFooter
        className={classnames(styles.footer, !isFooterVisible && styles.hidden)}
        isSticky={isFooterSticky}
        forwardRef={footerRef}
        innerRef={footerPortalRef}
      />
    </LayoutProviderContext.Provider>
  )
}

export default LayoutProvider
