import React, {Fragment, cloneElement} from 'react'
import classnames from 'classnames'
import Checkbox from 'components/rc/checkbox'
import ContentButton from 'components/ContentButton'
import CheckboxIcon from 'components/icons/redesigned/checkbox.svg'
import styles from './styles.css'

export type Props = Pick<
  React.HTMLProps<HTMLElement>,
  | 'className'
  | 'children'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'tabIndex'
> & {
  disabled?: boolean
  selected?: boolean
  bold?: boolean
  highlight?: boolean
  withCheckbox?: boolean
  container?: React.ReactElement
  color?: string
  level?: number
  type?: 'danger'
}

const DEFAULT_CONTAINER = <ContentButton />
const CHECKBOX_CONTAINER = <Checkbox />

export const MenuItem: React.FC<Props> = ({
  className,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  tabIndex,
  disabled,
  selected,
  withCheckbox,
  bold,
  highlight,
  container = withCheckbox ? CHECKBOX_CONTAINER : DEFAULT_CONTAINER,
  level,
  type,
  ...restProps
}) => {
  const hasColorProp = 'color' in restProps
  const {color} = restProps

  const style =
    color || level
      ? ({
          '--menu-item-custom-color': color || undefined,
          '--menu-item-custom-level': level || undefined
        } as React.CSSProperties)
      : undefined

  const useCheckbox = container === CHECKBOX_CONTAINER

  const resultChildren = (
    <Fragment>
      {selected && !useCheckbox && (
        <CheckboxIcon className={styles.buttonIcon} />
      )}
      <span className={styles.buttonContent}>{children}</span>
    </Fragment>
  )

  return cloneElement(
    container,
    {
      className: classnames(
        className,
        styles.button,
        (bold || selected) && styles.bold,
        (highlight || selected) && styles.highlight,
        disabled && styles.disabled,
        type && styles[type]
      ),
      ...(useCheckbox && {
        checked: selected,
        color: color ?? (hasColorProp ? 'var(--rui-ramblerBlue)' : undefined),
        type: 'fill'
      }),
      style,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onMouseUp,
      disabled,
      tabIndex
    },
    resultChildren
  )
}

export default MenuItem
