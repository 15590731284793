import {getItem, setItem} from '@rambler-tech/local-storage'
import {isObject} from 'utils/checkType'

const LOCAL_STORAGE_KEY = 'vpadmin:accountsVisits'

export const getAccountsVisits = (): Record<string, number> => {
  const data = getItem<Record<string, number>>(LOCAL_STORAGE_KEY)

  return isObject(data) ? data : {}
}

export const saveAccountVisit = (accountCode: string): void => {
  const currentData = getAccountsVisits()

  setItem(LOCAL_STORAGE_KEY, {
    ...currentData,
    [accountCode]: (currentData[accountCode] ?? 0) + 1
  })
}
