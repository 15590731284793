import React, {Suspense, lazy} from 'react'
import {useGlobalClassName} from 'utils/useGlobalClassName'
import PageFallback from 'components/Page/Redesigned/Fallback'
import {type Props} from './'

const Records = lazy(
  () =>
    import(
      /* webpackChunkName: "Records" */
      './'
    )
)

const LazyRecords: React.FC<Props> = (props) => {
  useGlobalClassName('redesigned-body')

  return (
    <Suspense fallback={<PageFallback />}>
      <Records {...props} />
    </Suspense>
  )
}

export default LazyRecords
