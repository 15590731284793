import {useEffect} from 'react'

export const useGlobalClassName = (className: string): void => {
  useEffect(() => {
    document.body.classList.add(className)

    return () => {
      document.body.classList.remove(className)
    }
  }, [className])
}
