import {api, config} from './api'

type RecordMetadata = {
  [metadataTag: string]: null | boolean | string | number[] | string[]
}

type FrontSettings = Record<
  string,
  boolean | string | number | null | undefined
>

type RecordFile = {
  id: number
  width: null | number
  height: number
  bitrate: null | number
  size: number
  error: null | unknown
  status: string
  name: string
  percent: null | number
  resource_type: string
}

type RecordUploadingState =
  | 'UPLOADING'
  | 'COMPLETING'
  | 'CONVERTING'
  | 'REPLICATING'
  | 'READY'
  | 'ERROR'

type PreviewLink = {
  w: number
  h: number
  url: string
}

type RecordResponseWithUploadId = RecordMetadata & {
  ad_template_id: number | null | undefined
  albums: {
    id: number
  }[]
  created_at: string
  current_screenshot: string | null | undefined
  current_screenshot_small: string | null | undefined
  description: string | null | undefined
  duration: number
  front_settings: null | FrontSettings
  id: number
  is_360: boolean | null | undefined
  is_hd: boolean | null | undefined
  is_processed: boolean | null | undefined
  name: string
  original_filename: null | string
  player_template_id: number | null | undefined
  recorded_at?: string
  shared_by?: string
  shared_records: {
    id: number
    account_id: number
  }[]
  tags: string[]
  updated_at: string
  upload_to_youtube: boolean
  user_email: string
  user_id: number
  uuid: string
  view_count: number
  youtube_id: string | null | undefined
  current_state: null | RecordUploadingState
  current_state_updated_at: null | string
  current_state_error_message: null | string
  is_reuploading: boolean
  age_restrictions_type: number | null | undefined
  click_url: unknown | null | undefined
  country_access_template_id: number | null | undefined
  origin_size: number
  percent: number
  parent_account_name?: string
  parent_record_id?: number
  priority: unknown | null | undefined
  published_from: string | null | undefined
  published_until: string | null | undefined
  publication_period: number | null | undefined
  preview_links: PreviewLink[] | null
  preview_status: null | string
  preview_time_start: null | number
  record_files: RecordFile[]
  screenshot_id: number
  screenshots: string[]
  site_access_template_id: number | null | undefined
  subtitles: string[]
  shared_at?: string
  use_encryptor: null | boolean
  view_percent_by_the_end: void
  previews: {
    id: number
    time_start: number
    duration: number
    links: PreviewLink[]
    status: 'in_progress' | 'ready' | 'error'
  }[]
  current_preview_id: number | null
  upload_id: string
}

export const postRecord = (
  {
    upload_file_size,
    is_mute,
    ...record
  }: {
    name: string
    upload_file_size: number
    is_mute: boolean
    original_filename: string
  },
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  }
): Promise<{
  data: RecordResponseWithUploadId
}> =>
  api(
    `${origin}/api/v2/records.json`,
    {
      record,
      upload_file_size,
      s3_upload: true,
      is_mute
    },
    'POST',
    {signal}
  )

export const uploadFile = (
  {
    id,
    upload_file_size,
    is_mute,
    ...record
  }: {
    id: number
    upload_file_size: number
    is_mute: boolean
    original_filename: string
  },
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  }
): Promise<{
  data: RecordResponseWithUploadId
}> =>
  api(
    `${origin}/api/v2/records/${id}/upload.json`,
    {
      record,
      upload_file_size,
      s3_upload: true,
      is_mute
    },
    'POST',
    {signal}
  )
